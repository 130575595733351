.ant-form{margin-top: 20px;
}

.ant-form-item-label > label {
    font-size: 13px;

  }

  .ant-input , .ant-select ,.ant-input-number , .ant-picker, .ant-input-number-input{
    border: 1px solid #bfbfbf;

  }

  .ant-input-number-group-addon {
    background-color: #f0f0f0;
    border: 1px solid #bfbfbf;
    color: #22304e;
}

fieldset{margin-top:20px;}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #ffffff;
    background: #22304e;
    border-color: #bfbfbf;
}


.ant-form-item-control-input-content{

  
}
.ant-form-item-control-input-content button {
    width:30%;
    margin:40px;
}
.ant-form-item-control-input-content button:first-child {

    background: #f45249;
    color: white;
}


.ant-form-item-control-input-content button:last-child {

    background: #22304e;
    color: white;
}
.ant-radio-button-wrapper:last-child {
    border-radius: 0 2px 2px 0;
}

.ant-layout, .ant-layout * {
    box-sizing: border-box;
}
.ant-radio-button-wrapper {


    border: 1px solid #bfbfbf;
  
}

.ant-radio-button-wrapper:first-child {
    border-left: 1px solid #bfbfbf;
    border-radius: 2px 0 0 2px;
}
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{border:none;}

  .ant-input-number-input {border:0px;}

  .ant-form legend {
    display: block;
    padding: 10px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    font-size: 12px;
    background: #f0f0f0;
    color: #22304e;
    text-transform: uppercase;
    margin-bottom: 28px;
    font-weight: 600;
    letter-spacing: 2px !important;
}


.ant-form-vertical .ant-form-item-label {   

   
   letter-spacing: 1px !important;
 }
  
 .ant-input-number-input-wrap input{text-align:right;padding-right: 30px;}